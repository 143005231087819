<template>
  <div>
    <h1 class="users-title text-left">{{ $t("general.users") }}</h1>
    <div class="filters-container text-left d-flex">
      <TrekioInput id="search" v-model="filter.searchString" :placeholder="$t('general.search')" searchInput/>
      <TrekioSelect v-model="filter.roleCode" :label="$t('inputLabels.role')" :text="filter.roleCode ? filter.roleCode : $t('general.allFemAndLifeless')">
        <TrekioSelectOption :value="null">{{ $t("general.allFemAndLifeless") }}</TrekioSelectOption>
        <TrekioSelectOption v-for="role in roles" :key="role.code" :value="role.code">{{ role.code }}</TrekioSelectOption>
      </TrekioSelect>

    </div>
    <p class="text-left" v-if="filteredUsers && filteredUsers.length === 0 && !isLoading">{{ $t("filter.noResults") }}</p>
      <UserList v-else-if="filteredUsers && !hasFilterChanged" :users="filteredUsers"/>
      <div v-if="isLoading || showMoreEnabled" class="page-loading-container">
        <div v-if="isLoading" class="loader-1 center paging-loading"><span></span></div>
        <TrekioButton v-else-if="showMoreEnabled && !isLoading" secondary @click="showMore">{{ $t("general.loadMore") }}</TrekioButton>
      </div>
      <div class="add-user-button-container">
        <router-link v-if="$store.state.currentUserRole == 'ADMIN_SUPERVISOR'" to='/admin/manageUser' class="add-user-button">
          <div class="plus position-fixed" style="bottom: 60px"><div class="warning"></div></div>
        </router-link>
        <router-link v-if="['ADMIN', 'ADMIN_SUPERVISOR'].includes($store.state.currentUserRole)" to='/admin/authorInvitation' class="invite-author-button">
        </router-link>
    </div>
  </div>
</template>

<script>
  import {FILTERED_USERS} from "../../api/graphql/query/UserQuery";
  import {ROLES} from "@/api/graphql/query/UserRoleQuery";
  import UserList from "@/components/admin/UserList";

  const SINGLE_PAGE_SIZE = 30

  export default {
      name: "UserListPage",
      components: {UserList},

      data: function () {
          return {
              filteredUsers: [],
              roles: [],
              users: [],

              filter: {
                searchString: "",
                roleCode: "",
              },
              pageSize: {
                page: 0,
                size: SINGLE_PAGE_SIZE,
                sortAttribute: "LENGTH_OF_STAY",
                sortDirection: "ASC"
              },
              showMoreEnabled: true,
              hasFilterChanged: true,
          }
      },
      beforeRouteLeave(to, from, next) {
        if (this.$store.getters.getLoggingOut == false) this.$store.commit('setUsersFilter', this.filter)
        next()
      },

      mounted() {
        if (this.$store.getters.getUsersFilter != null) this.filter = this.$store.getters.getUsersFilter
      },

      computed: {
        isLoading() {
          return this.$apollo.queries.filteredUsers.loading
        }
      },

      methods: {
        showMore () {
        this.pageSize.page++

        this.$apollo.queries.filteredUsers.fetchMore({
          variables: {
            filter: this.filter,
            pageSize: this.pageSize
          },

          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (fetchMoreResult.filteredUsers.length < SINGLE_PAGE_SIZE) {
              this.showMoreEnabled = false
            }

            return {
              filteredUsers: [
                ...previousResult.filteredUsers,
                ...fetchMoreResult.filteredUsers,
              ],
            }
          },
        })
      },
        setRole(chosenRole) {
          this.filter.roleCode = chosenRole
          this.$refs['role-dropdown'].hide(true)
        },
        onRowClick: function (userId) {
              this.$router.push({name: 'adminManageUser', params: {userId: userId}})
        },
      },

      watch: {
        filter: {
          handler(newValue, oldValue) {
            this.pageSize.page = 0
            this.hasFilterChanged = true
            this.showMoreEnabled = true
          },
          deep: true
        }
      },
      
      apollo: {
        filteredUsers: {
          query: FILTERED_USERS,
          variables () {
            return {
              filter: {
                searchString: this.filter.searchString,
                roleCode: this.filter.roleCode
              },
              pageSize: {
                page: 0,
                size: SINGLE_PAGE_SIZE,
                sortAttribute: "LENGTH_OF_STAY",
                sortDirection: "ASC"
              },
            }
          },
          result({data}) {
            if (data.filteredUsers.length < SINGLE_PAGE_SIZE) {
              this.showMoreEnabled = false
            }
            this.hasFilterChanged = false
          },
          fetchPolicy: 'cache-and-network',
        },
        roles: {
          query: ROLES,
        },
      },
  }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

  .add-user-button-container {
    position: absolute;
    right: 80px;
    max-width: 1200px;

    .add-user-button, .invite-author-button {
      width: 80px;
      height: 80px;
      right: 40px;
      position: fixed;
    }

    .invite-author-button {
      bottom: 160px;
      border: 3px solid $tr-black !important;
      border-radius: 50%;
      background-size: 74px !important;
      background-repeat: no-repeat;
      background-position: center;
      background: url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.292 10.648 38.5 21.228V37.5h-29V21.228l12.208-10.58a3.5 3.5 0 0 1 4.584 0Z" fill="%23fff" stroke="%23000"/><path fill="%23fff" stroke="%23000" d="M14.5 13.5h19v18h-19z"/><path d="M22.483 30.61 9.5 23v14.5h29V23l-12.983 7.61a3 3 0 0 1-3.034 0Z" fill="%23fff" stroke="%23000"/><path d="M19.5 21.5h9M24 17v9" stroke="%23000" stroke-width="2"/></svg>');
      background-color: $tr-white;

      &:hover {
        background-color: $tr-dark-gray !important;
      }
    }
  }

  .page-loading-container {
    max-width: 1200px;
    margin-top: 30px
  }

  .filters-container {
    padding-bottom: 50px;
    gap: 40px;
  }
  

</style>